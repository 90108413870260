import React, { Component, Fragment } from "react";
import FormField from "./Components/Common/FormField";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Tooltip from "./Components/Common/ToolTip";
import TextEditor from "./Components/Common/TextEditorField";
import { connect } from "react-redux";
import { withRouter } from "../../utils/utility";
import {
  Row,
  Col,
  Alert,
  Button,
  Input,
  FormGroup,
} from "reactstrap";
import SidebarProgress from "./Components/Sidebar/sidebar-progress";
import NumberField from "./Components/Common/NumberField";
import { saveEventDetails, getEventDetails } from "../../actions/eventActions";
import Loader from "../../components/Loader/Loader";
import FileUploadField from "./Components/Common/FileUploadField";
import qs from "query-string";
import ColorSelectorField from "./Components/Common/ColorSelectorField";
import SectionPricing from "./Components/Sections/section-pricing";
import SectionAddOns from "./Components/Sections/section-add-ons";
import { getAdminPaymentDetails } from "../../actions/paymentAction";
import PaymentConfigMissingModal from "../../components/Modals/payment-config-missing";

import isEmpty from "../../validation/is-empty";

let initialValues = {
  event_image: "",
  event_color: "",
  event_description: "",
  max_no_of_booking: 0,
  max_no_booking_status: "D",
  recurring_booking_status: "D",
  allow_rescheduling_status: "E",
  attendee_cancellation_status: "E",
  cancellation_policy: "",
  ita_booking_page_status: "D",
  ita_confirmation_page_status: "D",
  ita_reminder_email_status: "D",
  instruction_to_attendee: "",
};

let paidValues = {
  ...initialValues,
  coupon_codes_status: "D",
  recurrent_booking_discounts_status: "D",
  staff_commission_status: "D",
  staff_commission_rate: 0,
  // attendee_cancellation_status: "",
  event_prepaid_status: "D",
};

class AddNewEventDetails extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.isStripePaymentConfigured = React.createRef();
    this.isStripePaymentConfigured.current = null;

    this.state = {
      isLoading: false,
      errorMessage: "",
      pricing: [
        {
          item_name: "",
          price: "",
          qty: 1,
        },
      ],
      add_ons: [
        {
          item_name: "",
          price: "",
          qty: 0,
        },
      ],
      payments_type: "",
      payments_price: "",
      payments_flat_type: "percent",
      deposit_type: "",
      deposit_price: "",
      deposit_flat_per_type: "percent",
      pageLoading: false,
      is_prepayment_checked: false,
      showPaymentConfigMissingModal: false
    };
  }

  hidePaymentConfigMissingModal = () => {
     this.setState({
      showPaymentConfigMissingModal: false 
     }); 
  }

  /**
   * Get event type
   * @returns 
   */
  getType = () => {
    const { router } = this.props;
    const { location } = router || {};
    const { search } = location || {};
    const locationParse = qs.parse(search);
    const { type } = locationParse || {};
    return type;
  }

  /**
   * Get id
   */
  getId = () => {
    const { router } = this.props;
    const { params } = router || {};
    const { id } = params || {};
    return id;
  }

  componentDidMount() {
    const _this = this;
    const { getEventDetails, getAdminPaymentDetails } = this.props;
    const type = this.getType();
    const id = this.getId();


    getAdminPaymentDetails({
      data: {},
      onSuccess: function (response) {
        if (!isEmpty(response)) {
          if (!isEmpty(response.stripe_secret_key)) {
            _this.isStripePaymentConfigured.current = true;
          }     
        }
      },
      onError: function (error) {
      },
    });


    if (id) {
      this.setState({
        pageLoading: true,
      });

      const _this = this;
      getEventDetails({
        data: {
          id,
          type: type === "paid-event" ? type : "event",
        },
        onSuccess: function (response) {
          if (type === "free-event") {
            initialValues = {
              ...initialValues,
              ...response,
            };
          }

          if (type === "paid-event") {
            paidValues = {
              ...paidValues,
              ...response,
            };

            if (response.add_ons.length > 0 || response.pricing.length > 0) {
              _this.setState({
                add_ons: [...response.add_ons],
                pricing: [...response.pricing],
              });
            }

            // process prepayments
            if (response.hasOwnProperty("payments")) {
              const payments = [...response.payments];
              payments.map((payment, index) => {
                if (payment.prepayment_type == "deposit") {
                  _this.setState({
                    deposit_type: payment.prepayment_type,
                    deposit_price: payment.prepayment_type_price,
                    deposit_flat_per_type: payment.prepayment_type_flat_percent,
                    is_prepayment_checked: true,
                  });
                } else {
                  _this.setState({
                    payments_type: payment.prepayment_type,
                    payments_price: payment.prepayment_type_price,
                    payments_flat_type: payment.prepayment_type_flat_percent,
                    is_prepayment_checked: true,
                  });
                }
              });
            }
          }

          _this.setState({
            pageLoading: false,
          });
        },
        onError: function (error) {
          _this.setState({
            pageLoading: false,
          });
        },
      });
    }
  }

  /**
   * on cancel button handler
   */
  onCancel = () => {
    const { router } = this.props;
    const { navigate } = router || {};
    navigate("/admin/events/list");
  };

  /**
   * save event as draft
   */
  onGoBack = () => {
    const { router } = this.props;
    const { navigate } = router || {};
    const id = this.getId();
    const type = this.getType();
    navigate(`/admin/events/${id}/create?type=${type}`);

  };

  /**
   * save event as draft
   */
  onSaveAsDraft = () => {
    const data = this.formRef.current.values;

    const { saveEventDetails } = this.props;
    const type = this.getType();
    const id = this.getId();

    const {
      isLoading,
      pageLoading,
      errorMessage,
      pricing,
      add_ons,
      payments_type,
      payments_price,
      payments_flat_type,
      deposit_type,
      deposit_price,
      deposit_flat_per_type,
      is_prepayment_checked,
    } = this.state;

    const {
      max_no_of_booking,
      event_description,
      event_image: file,
      event_color,
      is_max_no_bookings,
      staff_commission_status,
      staff_commission_rate,
    } = data || {};

    if (event_color === "") {
      alert("Event color is required field.");
      return;
    }

    if (
      parseInt(staff_commission_rate) === 0 &&
      staff_commission_status === "E"
    ) {
      alert("Commission rate needs to be greater than 0");
      return;
    }

    if (
      parseInt(staff_commission_rate) >= 100 &&
      staff_commission_status === "E"
    ) {
      alert("Commission rate needs to be less than 100");
      return;
    }

    if (parseInt(max_no_of_booking) === 0 && is_max_no_bookings === "E") {
      alert("Max number of booking needs to be greater than 0");
      return;
    }

    this.setState({
      isLoading: true,
    });

    //allow resheduling status
    if (isEmpty(data.allow_rescheduling_status)) {
      data.allow_rescheduling_status = "D";
    }

    if (isEmpty(data.attendee_cancellation_status)) {
      data.attendee_cancellation_status = "D";
    }

    if (isEmpty(data.recurring_booking_status)) {
      data.recurring_booking_status = "D";
    }

    let newData = {
      ...data,
      id: parseInt(id),
    };

    if (type === "paid-event") {
      let payments = [
        {
          payment_type: payments_type,
          payment_type_flat_percent: payments_flat_type,
          price: payments_price,
        },
      ];

      if (deposit_type == "deposit" && deposit_price != "") {
        payments.push({
          payment_type: deposit_type,
          payment_type_flat_percent: deposit_flat_per_type,
          price: deposit_price,
        });
      }

      if (isEmpty(data.recurrent_booking_discounts_status)) {
        data.recurrent_booking_discounts_status = "D";
      }

      if (isEmpty(data.event_prepaid_status)) {
        data.event_prepaid_status = "D";
      }

      if (isEmpty(data.coupon_codes_status)) {
        data.coupon_codes_status = "D";
      }

      if (isEmpty(data.staff_commission_status)) {
        data.staff_commission_status = "D";
      }

      newData = {
        ...data,
        pricing,
        add_ons,
        payments,
        id: parseInt(id),
      };
    }

    saveEventDetails({
      data: {
        ...newData,
        event_image: file,
      },
      onSuccess: (eventId) => {
        const { router } = this.props;
        const { navigate } = router || {};
        navigate("/admin/events/list");
      },
      onError: (error) => {
        this.setState({
          isLoading: false,
          errorMessage: error,
        });
      },
    });
  };

  render() {
    const  _this = this;
    const type = this.getType();
    const id = this.getId();

    const { route } = this.props;

    const {
      isLoading,
      pageLoading,
      errorMessage,
      pricing,
      add_ons,
      payments_type,
      payments_price,
      payments_flat_type,
      deposit_type,
      deposit_price,
      deposit_flat_per_type,
      is_prepayment_checked,
      showPaymentConfigMissingModal
    } = this.state;

    return (
      <div className="create-event-wrapper">
        <div className="create-event-container">

        {showPaymentConfigMissingModal && (
            <PaymentConfigMissingModal
              showPaymentConfigMissingModal={showPaymentConfigMissingModal}
              handleClose={this.hidePaymentConfigMissingModal}
            />
          )}

          <Row className="flex-column-reverse flex-lg-row">
            <Col lg="9">
              <div className="event-card">
                <div className="event-card-head">
                  <h3 className="event-title">Event Details</h3>
                </div>
                {pageLoading ? (
                  <Loader isShowLoader={true} />
                ) : (
                  <Formik
                    innerRef={this.formRef}
                    initialValues={
                      type === "paid-event" ? paidValues : initialValues
                    }
                    enableReinitialize
                    onSubmit={(data) => {
                      const {
                        max_no_of_booking,
                        event_description,
                        event_image: file,
                        event_color,
                        is_max_no_bookings,
                        staff_commission_status,
                        staff_commission_rate,
                      } = data || {};

                      if (event_color === "") {
                        alert("Event color is required field.");
                        return;
                      }

                      if (
                        parseInt(staff_commission_rate) === 0 &&
                        staff_commission_status === "E"
                      ) {
                        alert("Commission rate needs to be greater than 0");
                        return;
                      }

                      if (
                        parseInt(staff_commission_rate) >= 100 &&
                        staff_commission_status === "E"
                      ) {
                        alert("Commission rate needs to be less than 100");
                        return;
                      }

                      if (
                        parseInt(max_no_of_booking) === 0 &&
                        is_max_no_bookings === "E"
                      ) {
                        alert(
                          "Max number of booking needs to be greater than 0"
                        );
                        return;
                      }

                      this.setState({
                        isLoading: true,
                      });

                      const { saveEventDetails } = this.props;

                      const { pricing, add_ons } = this.state;

                      //allow resheduling status
                      if (isEmpty(data.allow_rescheduling_status)) {
                        data.allow_rescheduling_status = "D";
                      }

                      if (isEmpty(data.attendee_cancellation_status)) {
                        data.attendee_cancellation_status = "D";
                      }

                      if (isEmpty(data.recurring_booking_status)) {
                        data.recurring_booking_status = "D";
                      }

                      let newData = {
                        ...data,
                        id: parseInt(id),
                      };

                      if (type === "paid-event") {
                        let payments = [
                          {
                            payment_type: payments_type,
                            payment_type_flat_percent: payments_flat_type,
                            price: payments_price,
                          },
                        ];

                        if (deposit_type == "deposit" && deposit_price != "") {
                          payments.push({
                            payment_type: deposit_type,
                            payment_type_flat_percent: deposit_flat_per_type,
                            price: deposit_price,
                          });
                        }

                        if (isEmpty(data.recurrent_booking_discounts_status)) {
                          data.recurrent_booking_discounts_status = "D";
                        }

                        if (isEmpty(data.event_prepaid_status)) {
                          data.event_prepaid_status = "D";
                        }

                        if (isEmpty(data.coupon_codes_status)) {
                          data.coupon_codes_status = "D";
                        }

                        if (isEmpty(data.staff_commission_status)) {
                          data.staff_commission_status = "D";
                        }

                        newData = {
                          ...data,
                          pricing,
                          add_ons,
                          payments,
                          id: parseInt(id),
                        };
                      }

                      saveEventDetails({
                        data: {
                          ...newData,
                          event_image: file,
                        },
                        onSuccess: (eventId) => {
                          const { router } = this.props;
                          const { navigate } = router || {};
                          navigate(
                            `/admin/events/create/${eventId}/step-3?type=${type}`
                          );
                        },
                        onError: (error) => {
                          this.setState({
                            isLoading: false,
                            errorMessage: error,
                          });
                        },
                      });
                    }}
                  >
                    {(formProps) => {
                      const { values, errors, touched, handleChange } =
                        formProps;

                      return (
                        <Form>
                          <div className="event-card-body">
                            <Row>
                              <Col xl="6">
                                <div className="cus_uploader form-group event-form-group">
                                  <label>
                                    Image
                                    <Tooltip />
                                  </label>
                                  <FileUploadField
                                    name="event_image"
                                    value={values.event_image}
                                    accept="image/*"
                                    placeholder="Choose Images"
                                    onChange={(file) => {
                                      handleChange({
                                        target: {
                                          name: "event_image",
                                          value: file,
                                        },
                                      });
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col xl="6">
                                <div className="form-group event-form-group">
                                  <label>
                                    Event Color
                                    <Tooltip />
                                  </label>
                                  <ColorSelectorField
                                    name="event_color"
                                    value={values.event_color}
                                    onChange={(name, value) => {
                                      handleChange({
                                        target: { name, value },
                                      });
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <div className="event-field-group">
                              <Row>
                                <Col md="6" lg="6">
                                  <div className="form-group event-form-group">
                                    <label>
                                      Description / Instruction
                                      <Tooltip />
                                    </label>
                                    <TextEditor
                                      name="event_description"
                                      value={values.event_description}
                                      onChange={(value) => {
                                        console.log('value', value);
                                        handleChange({
                                          target: {
                                            name: "event_description",
                                            value,
                                          },
                                        });
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col md="6" lg="6" className="mt-4 mt-md-0">
                                  <div className="">
                                    <div className="toggle_field big">
                                      <div className="box_content w-100 p-0">
                                        <div className="form-check form-switch mw-100">
                                          <input className="form-check-input" type="checkbox" id="maxNoOfBookings" name="max_no_booking_status"
                                            value={values.max_no_booking_status}
                                            checked={
                                              values.max_no_booking_status === "E"
                                                ? true
                                                : false
                                            }
                                            onChange={(value) => {
                                              handleChange({
                                                target: {
                                                  name: "max_no_booking_status",
                                                  value: value.target.checked ? "E" : "D",
                                                },
                                              });
                                            }}
                                          />
                                          <label className="form-check-label cus_label" htmlFor="maxNoOfBookings">
                                            Maximum No of Bookings
                                            <Tooltip />
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    {values.max_no_booking_status === "E" && (
                                      <div className="form-group">
                                        <Row>
                                          <Col md="6">
                                            <NumberField
                                              defaultValue={
                                                parseInt(
                                                  values.max_no_of_booking
                                                ) || 0
                                              }
                                              onChange={(value) => {
                                                handleChange({
                                                  target: {
                                                    name: "max_no_of_booking",
                                                    value,
                                                  },
                                                });
                                              }}
                                            />
                                          </Col>
                                        </Row>
                                      </div>
                                    )}
                                    <div className="toggle_field big">
                                      <div className="box_content w-100 p-0">
                                        <div className="form-check form-switch mw-100">
                                          <input className="form-check-input" type="checkbox" id="recurringBookingStatus" name="recurring_booking_status"
                                            value={values.recurring_booking_status}
                                            checked={
                                              values.recurring_booking_status === "E"
                                                ? true
                                                : false
                                            }
                                            onChange={(value) => {
                                              handleChange({
                                                target: {
                                                  name: "recurring_booking_status",
                                                  value: value.target.checked ? "E" : "D",
                                                },
                                              });
                                            }}
                                          />
                                          <label className="form-check-label cus_label" htmlFor="recurringBookingStatus">
                                            Allow Recurring Booking?
                                            <Tooltip />
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="toggle_field big">
                                      <div className="box_content w-100 p-0">
                                        <div className="form-check form-switch mw-100">
                                          <input className="form-check-input" type="checkbox" id="allowReschedulingStatus" name="allow_rescheduling_status"
                                            value={values.allow_rescheduling_status}
                                            checked={
                                              values.allow_rescheduling_status === "E"
                                                ? true
                                                : false
                                            }
                                            onChange={(value) => {
                                              handleChange({
                                                target: {
                                                  name: "allow_rescheduling_status",
                                                  value: value.target.checked ? "E" : "D",
                                                },
                                              });
                                            }}
                                          />
                                          <label className="form-check-label cus_label" htmlFor="allowReschedulingStatus">
                                            Allow Rescheduling?
                                            <Tooltip />
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="toggle_field big">
                                      <div className="box_content w-100 p-0">
                                        <div className="form-check form-switch mw-100">
                                          <input className="form-check-input" type="checkbox" id="attendeeCancellationStatus" name="attendee_cancellation_status"
                                            value={values.attendee_cancellation_status}
                                            checked={
                                              values.attendee_cancellation_status === "E"
                                                ? true
                                                : false
                                            }
                                            onChange={(value) => {
                                              handleChange({
                                                target: {
                                                  name: "attendee_cancellation_status",
                                                  value: value.target.checked ? "E" : "D",
                                                },
                                              });
                                            }}
                                          />
                                          <label className="form-check-label cus_label" htmlFor="attendeeCancellationStatus">
                                            Allow Attendee Cancellation?
                                            <Tooltip />
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                            {/* Paid Event */}
                            <Fragment>
                              {type === "paid-event" && (
                                <Fragment>
                                  <div className="event-field-group">
                                    <h3 className="field-title">
                                      Do You Require A Prepayment/Deposit ?
                                    </h3>
                                    <Row>
                                      <Col md="6" lg="6">
                                        <FormGroup
                                          tag="fieldset"
                                          className="event-form-group"
                                        >
                                          <div className="event-form-check">
                                            <input
                                              type="radio"
                                              checked={
                                                values.event_prepaid_status ===
                                                "E"
                                              }
                                              id="input-1"
                                              name="event_prepaid_status"
                                              value="E"
                                              onChange={({ target }) => {
                                                if (_this.isStripePaymentConfigured.current) {
                                                  const { name, value } = target;
                                                  handleChange({
                                                    target: {
                                                      name,
                                                      value,
                                                    },
                                                  });
                                                  this.setState({
                                                    is_prepayment_checked: true,
                                                  });
                                                } else {
                                                  _this.setState({
                                                    showPaymentConfigMissingModal: true 
                                                   });
                                                }
                                                
                                              }}
                                            ></input>
                                            <label htmlFor="input-1">
                                              <span></span>
                                              Yes
                                            </label>
                                          </div>
                                        </FormGroup>
                                        <div className="event-field-groups">
                                          <div className="form-group event-form-group">
                                            <div className="form-check-box">
                                              <input
                                                id="full_payment"
                                                type="radio"
                                                name="payment_type"
                                                checked={
                                                  is_prepayment_checked &&
                                                  payments_type ==
                                                  "full_payment"
                                                }
                                                onChange={() => {
                                                  if (_this.isStripePaymentConfigured.current) {
                                                    
                                                    if (!is_prepayment_checked)
                                                      return false;

                                                      this.setState({
                                                        payments_type:
                                                          "full_payment",
                                                        payments_price: "",
                                                      });

                                                    } else {
                                                      _this.setState({
                                                        showPaymentConfigMissingModal: true 
                                                       });
                                                    }  
                                                }}
                                              ></input>
                                              <label htmlFor="full_payment">
                                                <span></span>
                                                Full Payment
                                              </label>
                                            </div>
                                          </div>
                                          <Row>
                                            <Col xl="6">
                                              <div className="form-group event-form-group">
                                                <div className="form-check-box">
                                                  <input
                                                    id="deposit"
                                                    type="checkbox"
                                                    name="deposit_type"
                                                    checked={
                                                      is_prepayment_checked &&
                                                      deposit_type == "deposit"
                                                    }
                                                    onChange={(e) => {
                                                        if (_this.isStripePaymentConfigured.current) {
                                                          
                                                          if (e.target.checked) {

                                                            if (!is_prepayment_checked)
                                                              return false;

                                                            this.setState({
                                                              deposit_type:
                                                                "deposit",
                                                            });

                                                          } else {
                                                            this.setState({
                                                              deposit_type: "",
                                                              deposit_price: "",
                                                            });
                                                          }
                                                        } else {
                                                          _this.setState({
                                                            showPaymentConfigMissingModal: true 
                                                           });
                                                        }
                                                    }}
                                                  ></input>
                                                  <label htmlFor="deposit">
                                                    <span></span>
                                                    Deposit
                                                  </label>
                                                </div>
                                              </div>
                                              <div className="form-group form-field-group">
                                                <div className="group-field">
                                                  <select
                                                    name="deposit_flat_per_type"
                                                    value={deposit_flat_per_type}
                                                    onChange={({ target }) => {
                                                      const { value } =
                                                        target || {};
                                                      this.setState({
                                                        deposit_flat_per_type:
                                                          value,
                                                      });
                                                    }}
                                                  >
                                                    <option value="percent">
                                                      Percent %
                                                    </option>
                                                    <option value="flat">
                                                      Flat
                                                    </option>
                                                  </select>
                                                  <Input
                                                    type="number"
                                                    value={deposit_price}
                                                    onChange={({ target }) => {
                                                      if (
                                                        !is_prepayment_checked ||
                                                        deposit_type === ""
                                                      )
                                                        return false;
                                                      const { value } = target;
                                                      this.setState({
                                                        deposit_price: value,
                                                      });
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            </Col>
                                            <Col xl="6">
                                              <div className="form-group event-form-group">
                                                <div className="form-check-box">
                                                  <input
                                                    id="partial_payment"
                                                    type="radio"
                                                    name="payment_type"
                                                    checked={
                                                      is_prepayment_checked &&
                                                      payments_type ==
                                                      "partial_payment"
                                                    }
                                                    onChange={(e) => {
                                                      if (_this.isStripePaymentConfigured.current) {
                                                        if (!is_prepayment_checked)
                                                          return false;

                                                          this.setState({
                                                            payments_type:
                                                              "partial_payment",
                                                          });
                                                      } else {
                                                        _this.setState({
                                                          showPaymentConfigMissingModal: true 
                                                         });
                                                      }
                                                    }}
                                                  ></input>
                                                  <label htmlFor="partial_payment">
                                                    <span></span>
                                                    Partial Payment
                                                  </label>
                                                </div>
                                              </div>
                                              <div className="form-group form-field-group">
                                                <div className="group-field">
                                                  <select
                                                    name="payment_type_flat_percent"
                                                    value={payments_flat_type}
                                                    onChange={({ target }) => {
                                                      const { name, value } =
                                                        target || {};
                                                      // payments_flat_type["partial_payment"] = value;
                                                      this.setState({
                                                        payments_flat_type:
                                                          value,
                                                      });
                                                      // payments_flat_type = value;
                                                    }}
                                                  >
                                                    <option value="percent">
                                                      Percent %
                                                    </option>
                                                    <option value="flat">
                                                      Flat
                                                    </option>
                                                  </select>
                                                  <Input
                                                    value={payments_price}
                                                    type="number"
                                                    onChange={({ target }) => {
                                                      if (
                                                        !is_prepayment_checked ||
                                                        payments_type !==
                                                        "partial_payment"
                                                      )
                                                        return false;
                                                      const { value } = target;
                                                      this.setState({
                                                        payments_price: value,
                                                      });
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            </Col>
                                          </Row>
                                        </div>
                                      </Col>
                                      <Col md="6" lg="6" className="mt-4 mt-md-0">
                                        <FormGroup
                                          tag="fieldset"
                                          className="event-form-group"
                                        >
                                          <div className="event-form-check">
                                            <input
                                              type="radio"
                                              id="input-2"
                                              checked={
                                                values.event_prepaid_status ===
                                                "D"
                                              }
                                              name="event_prepaid_status"
                                              value="D"
                                              onChange={({ target }) => {
                                                const { name, value } = target;
                                                handleChange({
                                                  target: {
                                                    name,
                                                    value,
                                                  },
                                                });
                                                this.setState({
                                                  is_prepayment_checked: false,
                                                });
                                              }}
                                            ></input>
                                            <label htmlFor="input-2">
                                              <span></span>
                                              No, I will get paid at the event
                                            </label>
                                          </div>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </div>
                                  {/* coupon code */}
                                  <div className="event-field-group">
                                    <Row>
                                      <Col md="6" lg="4">
                                        <div className="toggle_field big">
                                          <div className="box_content p-0">
                                            <div className="form-check form-switch mw-100">
                                              <input className="form-check-input float-none" type="checkbox" id="couponCodesStatus" name="coupon_codes_status"
                                                value={values.coupon_codes_status}
                                                checked={
                                                  values.coupon_codes_status === "E"
                                                    ? true
                                                    : false
                                                }
                                                onChange={(value) => {
                                                  handleChange({
                                                    target: {
                                                      name: "coupon_codes_status",
                                                      value: value.target.checked ? "E" : "D",
                                                    },
                                                  });
                                                }}
                                              />
                                              <label className="form-check-label cus_label mr-0 ml-4" htmlFor="couponCodesStatus">
                                                Coupon Codes
                                              </label>
                                            </div>
                                          </div>
                                        </div>

                                      </Col>
                                      <Col md="6" lg="4">
                                        <div className="toggle_field big">
                                          <div className="box_content p-0">
                                            <div className="form-check form-switch mw-100 d-flex">
                                              <input className="form-check-input float-none" type="checkbox" id="recurrentSookingSiscountsStatus" name="recurrent_booking_discounts_status"
                                                value={values.recurrent_booking_discounts_status}
                                                checked={
                                                  values.recurrent_booking_discounts_status === "E"
                                                    ? true
                                                    : false
                                                }
                                                onChange={(value) => {
                                                  handleChange({
                                                    target: {
                                                      name: "recurrent_booking_discounts_status",
                                                      value: value.target.checked ? "E" : "D",
                                                    },
                                                  });
                                                }}
                                              />
                                              <label className="form-check-label cus_label mr-0 ml-4" htmlFor="recurrentSookingSiscountsStatus">
                                              Enable Recurrent Booking Discounts
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </Col>
                                      <Col md="6" lg="4">
                                        <div className="toggle_field big">
                                          <div className="box_content p-0">
                                            <div className="form-check form-switch mw-100">
                                              <input className="form-check-input float-none" type="checkbox" id="staffCommissionStatus" name="staff_commission_status"
                                                value={values.staff_commission_status}
                                                checked={
                                                  values.staff_commission_status === "E"
                                                    ? true
                                                    : false
                                                }
                                                onChange={(value) => {
                                                  handleChange({
                                                    target: {
                                                      name: "staff_commission_status",
                                                      value: value.target.checked ? "E" : "D",
                                                    },
                                                  });
                                                }}
                                              />
                                              <label className="form-check-label cus_label mr-0 ml-4" htmlFor="staffCommissionStatus">
                                              Commission Status
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        {values.staff_commission_status ===
                                        "E" && (
                                          <div className="form-group">
                                            <Row>
                                              <Col md="9">
                                                <NumberField
                                                  defaultValue={
                                                    parseInt(
                                                      values.staff_commission_rate
                                                    ) || 0
                                                  }
                                                  onChange={(value) => {
                                                    handleChange({
                                                      target: {
                                                        name: "staff_commission_rate",
                                                        value,
                                                      },
                                                    });
                                                  }}
                                                />
                                              </Col>
                                            </Row>
                                          </div>
                                        )}
                                      </Col>
                                    </Row>
                                  </div>
                                  {/* pricing */}
                                  <div className="event-field-group">
                                    <h3 className="field-title">Pricing</h3>
                                    <SectionPricing
                                      pricing={pricing}
                                      onChange={(items) => {
                                        this.setState({
                                          pricing: items,
                                        });
                                      }}
                                    />
                                  </div>
                                  {/* add on */}

                                  <div className="event-field-group mb-30">
                                    <h3 className="field-title">Add-on</h3>
                                    <SectionAddOns
                                      addOns={add_ons}
                                      onChange={(items) => {
                                        this.setState({
                                          add_ons: items,
                                        });
                                      }}
                                    />
                                  </div>
                                </Fragment>
                              )}
                              <div className="cancel-wrapper mt-30">
                                <Row>
                                  <Col md="6" lg="6">
                                    <FormField
                                      type="text"
                                      name="cancellation_policy"
                                      label="Cancellation Policy"
                                      placeholder="From Text"
                                      showLabel={true}
                                      value={values.cancellation_policy}
                                      errors={errors}
                                      touched={touched}
                                    />
                                  </Col>
                                  <Col md="6" lg="6" className="mt-4 mt-md-0">
                                    <FormField
                                      type="text"
                                      name="instruction_to_attendee"
                                      label="Instructions to Attendee"
                                      placeholder="From Text"
                                      showLabel={true}
                                      value={values.instruction_to_attendee}
                                      errors={errors}
                                      touched={touched}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md="4" lg="3" className="mt-4 mt-md-0">
                                    <div className="form-group event-form-group">
                                      <div className="form-check-box">
                                        <input
                                          id="booking_page"
                                          type="checkbox"
                                          checked={
                                            values.ita_booking_page_status ===
                                              "E"
                                              ? true
                                              : false
                                          }
                                          name="ita_booking_page_status"
                                          value={values.ita_booking_page_status}
                                          onChange={(event) => {
                                            const { target } = event || {};
                                            const { name, value } =
                                              target || {};
                                            let defaultValue = "D";
                                            if (value === "D") {
                                              defaultValue = "E";
                                            }

                                            handleChange({
                                              target: {
                                                name,
                                                value: defaultValue,
                                              },
                                            });
                                          }}
                                        ></input>
                                        <label htmlFor="booking_page">
                                          <span></span>
                                          Booking Page
                                        </label>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md="4" lg="3">
                                    <div className="form-group event-form-group">
                                      <div className="form-check-box">
                                        <input
                                          id="confirmation_page"
                                          type="checkbox"
                                          checked={
                                            values.ita_confirmation_page_status ===
                                              "E"
                                              ? true
                                              : false
                                          }
                                          name="ita_confirmation_page_status"
                                          value={
                                            values.ita_confirmation_page_status
                                          }
                                          onChange={(event) => {
                                            const { target } = event || {};
                                            const { name, value } =
                                              target || {};
                                            let defaultValue = "D";
                                            if (value === "D") {
                                              defaultValue = "E";
                                            }

                                            handleChange({
                                              target: {
                                                name,
                                                value: defaultValue,
                                              },
                                            });
                                          }}
                                        ></input>
                                        <label htmlFor="confirmation_page">
                                          <span></span>
                                          Confirmation Page
                                        </label>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md="4" lg="3">
                                    <div className="form-group event-form-group">
                                      <div className="form-check-box">
                                        <input
                                          id="reminder_email"
                                          type="checkbox"
                                          checked={
                                            values.ita_reminder_email_status ===
                                              "E"
                                              ? true
                                              : false
                                          }
                                          name="ita_reminder_email_status"
                                          value={
                                            values.ita_reminder_email_status
                                          }
                                          onChange={(event) => {
                                            const { target } = event || {};
                                            const { name, value } =
                                              target || {};
                                            let defaultValue = "D";
                                            if (value === "D") {
                                              defaultValue = "E";
                                            }

                                            handleChange({
                                              target: {
                                                name,
                                                value: defaultValue,
                                              },
                                            });
                                          }}
                                        ></input>
                                        <label htmlFor="reminder_email">
                                          <span></span>
                                          Reminder Email
                                        </label>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </Fragment>
                          </div>
                          <div className="event-card-footer">
                            <Row>
                              <Col md="3" lg="3">
                                <Button
                                  className="btn btn-danger"
                                  type="button"
                                  disabled={isLoading}
                                  onClick={this.onCancel}
                                >
                                  Cancel
                                </Button>
                              </Col>
                              <Col md="3" lg="3" className="mt-3 mt-md-0">
                                <Button
                                  className="btn btn-outline"
                                  type="button"
                                  disabled={isLoading}
                                  onClick={this.onSaveAsDraft}
                                >
                                  Save as Draft
                                </Button>
                              </Col>

                              <Col md="3" lg="3" className="mt-3 mt-md-0">
                                <Button
                                  className="btn btn-success"
                                  type="button"
                                  disabled={isLoading}
                                  onClick={this.onGoBack}
                                >
                                  Back
                                </Button>
                              </Col>

                              <Col md="3" lg="3" className="mt-3 mt-md-0">
                                <Button
                                  type="submit"
                                  className="btn btn-app"
                                  disabled={isLoading}
                                >
                                  Next Step
                                </Button>
                              </Col>
                            </Row>
                            {errorMessage !== "" && (
                              <Alert
                                color="danger"
                                className="mt-4 text-center p-10"
                              >
                                {errorMessage}
                              </Alert>
                            )}
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                )}
              </div>
            </Col>
            <Col lg="3">
              <SidebarProgress route={route} props={this.props} />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default connect(null, {
  saveEventDetails,
  getEventDetails,
  getAdminPaymentDetails,
})(withRouter(AddNewEventDetails));

// export default AddNewEventDetails;