import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getStaffs, softDeleteStaff } from "../../../../actions/staffActions";
import { Table, Dropdown, Menu } from "antd";
import { Col, Row } from "reactstrap";
import Loader from "../../../../components/Loader/Loader";
import isEmpty from "../../../../validation/is-empty";
import StaffDelete from "./Delete";
import { Edit } from "react-feather";
import "antd/dist/antd.css";
import { notification } from "../../../../utils/utility";
import { Link } from "react-router-dom";
import Filter from "../../../../components/Filter/Filter";
import StaffFilters from "./StaffFilters";
import PaginatorDropdown from "../../../../components/Datatable/PaginationDropdown";
import ActionDots from "../../../../components/Datatable/ActionDots";
import { toggleFilter } from "../../../../actions/filterActions";
import StaffHasBookingModal from "./StaffHasBookingModal";

const StaffListing = (props) => {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [showStaffHasBookings, setShowStaffHasBookings] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: false,
  });

  const [data, setData] = useState([]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_, data) => {
        return (
          <Link className="name" to={`/admin/staff/edit/${data.id}`}>
            {data.profile_image !== "" ? (
              <img
                className="prof_img"
                src={`https://www.staging.thrive-booking.com/backend/public/${data.profile_image}`}
                alt="Profile Img"
              />
            ) : (
              <div className="name_initial">
                {data.first_name.charAt(0) + data.last_name.charAt(0)}
              </div>
            )}
            {data.first_name + " " + data.last_name}
          </Link>
        );
      },
      sorter: false,
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: true,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      sorter: true,
    },

    {
      title: "City",
      dataIndex: "city",
      sorter: true,
    },

    {
      title: "Country",
      dataIndex: "country",
      render: (_, data) => {
        return data.country.label;
      },
      sorter: true,
    },
    {
      title: "Timezone",
      dataIndex: "timezone",
      render: (_, data) => {
        return data.timezone;
      },
      sorter: true,
    },
    {
      title: "Account Status",
      dataIndex: "user_account_status",
      render: (_, data) => {
        return data.user_account_status === "active" ? (
          <span className="status active">Active</span>
        ) : (
          <span className="status deactive">Pending</span>
        );
      },
      sorter: true,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, data) => {
        const menu = (
          <Menu
            items={[
              {
                label: (
                  <Link
                    to={`/admin/staff/edit/${data.id}`}
                    className="text-dark"
                  >
                    <Edit className="mr-50" size={15} /> Edit
                  </Link>
                ),
                key: "0",
              },
              {
                type: "divider",
              },
              {
                label: (
                  <>
                    <StaffDelete onDeleteRecord={onDeleteRecord} data={data} />
                  </>
                ),
                key: "1",
              },
            ]}
          />
        );
        return (
          <div className="actions cursor-pointer">
            <Dropdown overlay={menu} trigger={["click"]}>
              <a onClick={(e) => e.preventDefault()}>
                <ActionDots />
              </a>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  /**
   * on delete record
   * @param {*} id
   */
  const onDeleteRecord = (id) => {
    const { softDeleteStaff } = props;

    setIsDataLoading(true);

    softDeleteStaff({
      data: {
        id,
      },
      onSuccess: function (data) {
        notification.success("Record successfully deleted");
        handleTableChange(
          {
            current: 1,
            pageSize: 10,
          },
          {},
          {}
        );
        setIsDataLoading(false);
      },
      onError: function (error) {
        setIsDataLoading(false);

        const { data } = error.response;
        if (parseInt(data.code) === 255) {
          setShowStaffHasBookings(true);
        } else {
          notification.error(
            "An error occured during delete, Please try later"
          );
        }
      },
    });
  };

  /**
   * on filter data table
   * @param {*} name
   * @param {*} value
   */
  const filterRecords = () => {
    const { toggleFilter, filter } = props;
    toggleFilter(false);
    handleTableChange(
      {
        ...pagination,
      },
      {
        ...filter.searchFilters,
      },
      {}
    );
  };

  /**
   * on clear filter handler
   */
  const clearFilterHandler = () => {
    handleTableChange(
      {
        ...pagination,
      },
      {},
      {}
    );
  };

  /**
   * prepare data parameters to be send to server
   * @param {*} params
   */
  const prepareDataParam = (params) => {
    let sortField = "id";
    let sortOrder = "asc";

    if (!isEmpty(params.sortField) && !isEmpty(params.sortOrder)) {
      sortField = params.sortField;
      sortOrder = params.sortOrder === "ascend" ? "asc" : "desc";
    }

    return {
      pageSize: params.pagination.pageSize,
      pageNumber: params.pagination.current,
      sortField,
      sortOrder,
      filter: {
        ...params.filters,
      },
    };
  };

  /**
   * fetch data table data
   * @param {*} params
   */
  const fetchTableData = (params = {}) => {
    const { getStaffs } = props;
    setIsDataLoading(true);
    getStaffs({
      data: prepareDataParam(params),
      onSuccess: function (data) {
        setData(data.entity);
        setPagination({
          ...params.pagination,
          total: data.totalCount,
        });
        setIsDataLoading(false);
      },
      onError: function (error) {
        setData([]);

        setIsDataLoading(false);
      },
    });
  };

  /**
   * on page size drop down change
   * @param {*} e  event
   */
  const onPageSizeChange = (e) => {
    handleTableChange(
      {
        current: 1,
        pageSize: e.target.value,
        total: pagination.total,
      },
      {},
      {}
    );
  };

  /**
   * on handle table change
   * @param {*} pagination
   * @param
   */
  const handleTableChange = (pagination, filters, sorter) => {
    fetchTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      filters,
    });
  };

  useEffect(() => {
    fetchTableData({ pagination });
  }, []);

  return (
    <>
      <Row className="app-user-list">
        <Loader isShowLoader={isPageLoading} />
        <Col sm="12">
          <Filter
            name="Directory Info"
            className="with_btn"
            onFilterRecords={filterRecords}
            onClearFilterHandler={clearFilterHandler}
            showButtons={true}
            buttons={
              <button
                className="btn btn-outline btn-add mr-4"
                type="button"
                style={{ width: "126px" }}
              >
                <Link to={"/admin/staff/create"} style={{ color: "inherit" }}>
                  Add New
                </Link>
              </button>
            }
          >
            <StaffFilters />
          </Filter>
        </Col>
        <Col sm="12">
          <Table
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={data}
            pagination={pagination}
            loading={isDataLoading}
            onChange={handleTableChange}
          ></Table>
        </Col>
        <Col
          sm="6"
          className={data.length !== 0 ? "table_paginaton" : "table_no_pagi"}
        >
          <PaginatorDropdown
            onPageSizeChange={onPageSizeChange}
            pageSize={pagination.pageSize}
          />
        </Col>
      </Row>
      {showStaffHasBookings && (
        <StaffHasBookingModal
          showStaffHasBookings={showStaffHasBookings}
          toggle={() => setShowStaffHasBookings(false)}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  filter: state.filter,
});

export default connect(mapStateToProps, {
  getStaffs,
  softDeleteStaff,
  toggleFilter,
})(StaffListing);
