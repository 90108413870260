import React, { Component } from "react";
import TimeLeftIcon from "../../assets/icons/time_left.svg";
import ClockIcon from "../../assets/icons/Clock.svg";
import CalendarIcon from "../../assets/icons/calendar.svg";
import { getBookingByRescheduleToken } from "../../actions/bookingAction";
import ErrorPage from "../errors/ErrorPage";
import { connect } from "react-redux";
import {
  withRouter,
  notification,
  getDBFormatedDate,
} from "../../utils/utility";
import Loader from "../../components/Loader/Loader";
import isEmpty from "../../validation/is-empty";
import {
  getEvent,
  savePaidBooking,
  saveFreeBooking,
} from "../../actions/bookingAction";

import { rescheduleEvent } from "../../actions/admin/scheduled-event-actions";

import moment from "moment";
import BookingStep1 from "./booking-step-1";
import BookingStep4 from "./booking-step-4";

class RescheduleBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      step: 1,
      event: {},
      company: {},
      selectedStaffId: null,
      selectedSlot: null,
      endSlotTime: null,
      bookingDate: null,
      name: null,
      email: null,
      timezondId: "",
      timezoneValue: "",
      specialRibbon: null,
      error: null,
      formerData: null,
    };
  }

  /**
   * on select staff
   * @param {*} staffId
   */
  onSelectStaff = (staffId) => {
    this.setState({ selectedStaffId: staffId });
  };

  /**
   * on select timezone
   */
  onSelectTimzone = (timezondId, timezoneValue) => {
    this.setState({ timezondId, timezoneValue });
  };

  /**
   * on select slot
   * @param {*} slot
   */
  onSelectSlot = (slot) => {
    this.setState({
      selectedSlot: slot,
    });
  };

  /**
   * on calendar date select
   * @param {*} date
   */
  onSelectDate = (date) => {
    this.setState({
      bookingDate: getDBFormatedDate(new Date(date)),
    });
  };

  /**
   * on reschedule
   */
  onReschedule = () => {
    const _this = this;

    _this.setState({
      isLoading: true,
    });

    const { rescheduleEvent } = _this.props;

    const { selectedStaffId, selectedSlot, bookingDate, timezoneValue, event } =
      this.state;

    const bookingDateTime = bookingDate + " " + selectedSlot;
    let endDate = new Date(bookingDateTime);
    if (!isNaN(event.timeslot)) {
      endDate.setTime(endDate.getTime() + event.timeslot * 60 * 1000);
    }

    const endSlot =
      ("0" + endDate.getHours()).slice(-2) +
      ":" +
      ("0" + endDate.getMinutes()).slice(-2);
    _this.setState({
      endSlotTime: endSlot,
    });

    let bookingDetails = {
      event_id: event.id,
      booking_date_time: bookingDateTime,
      start_time: selectedSlot + ":00",
      end_time: endSlot + ":00",
      reschedule_reason: "",
      timezone: timezoneValue,
      token: _this.getToken(),
      is_invitee: _this.isInvitee() ? 'E' : 'D'
    };

    if (!isEmpty(selectedStaffId)) {
      bookingDetails.staff_id = selectedStaffId;
    }

    rescheduleEvent({
      data: {
        ...bookingDetails,
      },
      onSuccess: (data) => {
        _this.setState({
          isLoading: false,
        });
        _this.onUpdateStep(2);
      },
      onError: (error) => {
        _this.setState({
          isLoading: false,
        });
      },
    });
  };

  /**
   * on update step
   * @param {*} step
   */
  onUpdateStep = (step) => {
    this.setState({
      step,
    });
  };

  /**
   * 
   */
  isInvitee = () => {
    const { router } = this.props;
    const { params } = router || {};
    const { time } = params || {};
    return !isEmpty(time);
  }

  /**
   * Get token
   */
  getToken = () => {
    const { router } = this.props;
    const { params } = router || {};
    const { token } = params || {};
    return token;
  };

  /**
   * Get event details
   * @param {*} slug
   */
  getEventDetails = (bookingData) => {
    const _this = this;
    const { getEvent } = _this.props;
    getEvent({
      data: {
        slug:bookingData.event_url
      },
      onSuccess: function (response) {
        const { event, company } = response;
        let selectedStaffId = "";
        if (event.staffs && event.staffs.length > 0 && event.allow_attendee_select_staff == 'E') {
          selectedStaffId = bookingData.host_id;
        }

        _this.setState({
          isLoading: false,
          event,
          company,
          selectedStaffId,
          specialRibbon: !isEmpty(response.special_ribbon)
            ? response.special_ribbon.special_announcement
            : null,
        });
      },
      onError: (error) => {
        _this.setState({
          isLoading: false,
          error: "This event is invalid or no longer available",
        });
        notification.error(error.response.data.message);
      },
    });
  };

  componentDidMount() {
    const _this = this;
    const { getBookingByRescheduleToken } = _this.props;

    _this.setState({
      isLoading: true,
    });

    getBookingByRescheduleToken({
      data: {
        token: _this.getToken(),
      },
      onSuccess: function (response) {
        console.log("response ", response);
        _this.setState({ formerData: response });
        if (!isEmpty(response.event_url)) {
          _this.getEventDetails(response);
        }
      },
      onError: function (error) {
        _this.setState({
          isLoading: false,
          error: "Invalid token, or token is expired",
        });
      },
    });
  }

  render() {
    const {
      step,
      event,
      selectedStaffId,
      selectedSlot,
      endSlotTime,
      bookingDate,
      timezoneValue,
      specialRibbon,
      isLoading,
      error,
      formerData,
    } = this.state;

    return (
      <>
        {error ? (
          <ErrorPage message={error} />
        ) : (
          <>
            <Loader isShowLoader={isLoading} />
            <div className="rd_vacationfilterpart cancel_eve">
              {formerData && (
                <div className="col-lg-4 col-md-5">
                  <div className="txt">
                    <h4>{formerData.invitee_name}</h4>
                    <h2>{formerData.event_name}</h2>
                    <div className="info">
                      <p className="time_left">
                        <img src={TimeLeftIcon} alt="Time Icon" />
                        <span>{formerData.event_duration}</span>
                      </p>
                      <p>
                        <img src={CalendarIcon} alt="Time Icon" />
                        <span>
                          {`${moment(formerData.start_time, "hh:mm").format(
                            "hh:mm a"
                          )} - ${moment(formerData.end_time, "hh:mm").format(
                            "hh:mm a"
                          )}, ${moment(formerData.when, "DD-MM-YYYY").format(
                            "dddd, MMMM DD, YYYY"
                          )}`}
                        </span>
                      </p>
                      <p>
                        <img src={ClockIcon} alt="Time Icon" />
                        <span>{formerData.invitee_timezone}</span>
                      </p>
                    </div>
                  </div>
                </div>
              )}

              <div className="col-lg-8 col-md-7">
                <div className="txt">
                  {step === 1 && !isEmpty(event) && (
                    <BookingStep1
                      isRescheduleEvent={true}
                      reschedule={this.onReschedule}
                      event={event}
                      bookingStaffId={selectedStaffId}
                      specialRibbon={specialRibbon}
                      timezoneValue={timezoneValue}
                      onSelectTimzone={this.onSelectTimzone}
                      onSelectStaff={this.onSelectStaff}
                      onSelectSlot={this.onSelectSlot}
                      onSelectDate={this.onSelectDate}
                    />
                  )}
                  {step === 2 && (
                    <BookingStep4
                      specialRibbon={specialRibbon}
                      timezoneValue={timezoneValue}
                      event={event}
                      selectedSlot={selectedSlot}
                      endSlotTime={endSlotTime}
                      bookingDate={bookingDate}
                      guests={[]}
                    />
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default connect(null, {
  getBookingByRescheduleToken,
  getEvent,
  savePaidBooking,
  saveFreeBooking,
  rescheduleEvent,
})(withRouter(RescheduleBooking));
