import Bootstrap from "../../views/site/bootstrap/Bootstrap";
//import Booking from "../views/site/booking/Booking";
import LandingPage from "../../views/site/LandingPage";
//import Login from "../views/site/Login";
import Signup from "../../views/site/Signup";
import StaffSignup from "../../views/staff/Signup";
import StaffSignupComplete from "../../views/staff/SignupComplete";
import StaffProfileStepOne from "../../views/staff/ProfileSteps/Components/ProfileStepOne";
import StaffProfileStepTwo from "../../views/staff/ProfileSteps/Components/ProfileStepTwo";
import StaffProfileStepThree from "../../views/staff/ProfileSteps/Components/ProfileStepThree";
import StaffProfileStepFour from "../../views/staff/ProfileSteps/Components/ProfileStepFour";

import Payments from "../../views/site/Payments";
import ProfileStepOne from "../../views/site/ProfileSteps/Components/ProfileStepOne";
import ProfileStepTwo from "../../views/site/ProfileSteps/Components/ProfileStepTwo";
import ProfileStepThree from "../../views/site/ProfileSteps/Components/ProfileStepThree";
import ProfileStepFour from "../../views/site/ProfileSteps/Components/ProfileStepFour";
import ConfirmEmail from "../../views/site/ConfrimEmail";
import WelcomeToMeetOcto from "../../views/site/WelcomeToMeetOcto";
import DoneAndWelcome from "../../views/site/DoneAndWelcome";
import ThanksForPayment from "../../views/site/ThanksForPayment";

import Booking from "../../views/booking/index";
import TimeToEmailBooking from "../../views/booking/time-to-email-booking";
import CompanyPage from "../../views/booking/company-page";
import CancelBooking from "../../views/booking/cancel-booking";
import RescheduleBooking from "../../views/booking/reschedule-booking";
import AdhocBooking from "../../views/booking/adhoc";

const routes = [
  {
    component: Bootstrap,
    exact: true,
    path: "/",
  },
  {
    component: LandingPage,
    exact: true,
    path: "/landingPage",
  },
  // {
  //   component: Login,
  //   exact: true,
  //   path: "/login",
  // },
  {
    component: Signup,
    exact: true,
    path: "/signup",
  },
  {
    component: StaffSignup,
    exact: true,
    path: "/staff/register/:token",
  },
  {
    component: StaffSignupComplete,
    exact: true,
    path: "/staff/signup-complete",
  },
  {
    component: StaffProfileStepOne,
    exact: true,
    path: "/staff/profile-step-one",
  },
  {
    component: StaffProfileStepTwo,
    exact: true,
    path: "/staff/profile-step-two",
  },
  {
    component: StaffProfileStepThree,
    exact: true,
    path: "/staff/profile-step-three",
  },
  {
    component: StaffProfileStepFour,
    exact: true,
    path: "/staff/profile-step-four",
  },
  {
    component: Payments,
    exact: true,
    path: "/signup/payment",
  },
  {
    component: ProfileStepOne,
    exact: true,
    path: "/signup/profile-step-one",
  },
  {
    component: ProfileStepTwo,
    exact: true,
    path: "/signup/profile-step-two/:id",
  },
  {
    component: ProfileStepThree,
    exact: true,
    path: "/signup/profile-step-three",
  },
  {
    component: ProfileStepFour,
    exact: true,
    path: "/signup/profile-step-four",
  },
  {
    component: ConfirmEmail,
    exact: true,
    path: "/confirmEmail",
  },
  {
    component: WelcomeToMeetOcto,
    exact: true,
    path: "/welcomeToMeetOcto",
  },
  {
    component: DoneAndWelcome,
    exact: true,
    path: "/doneAndWelcome",
  },
  {
    component: ThanksForPayment,
    exact: true,
    path: "/signup/thanksForPayment",
  },
  {
    component: CompanyPage,
    exact: true,
    path: "/company/:slug",
  },
  {
    component: Booking,
    exact: true,
    path: "/booking/:slug",
  },
  {
    component: TimeToEmailBooking,
    exact: true,
    path: "/booking/:slug/:dated/:slot",
  },
  {
    component: CancelBooking,
    exact: true,
    path: "/booking/cancel/:token/:time?",
  },
  {
    component: RescheduleBooking,
    exact: true,
    path: "/booking/reschedule/:token/:time?",
  },
  {
    component: AdhocBooking,
    exact: true,
    path: "/adhoc-booking/:slug/:dated?/:slot?",
  },
];

export default routes;
