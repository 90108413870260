import React, { Component } from "react";
import {
  getEvent,
  savePaidBooking,
  saveFreeBooking,
} from "../../actions/bookingAction";
import { notification, withRouter } from "../../utils/utility";
import { connect } from "react-redux";
import BookingStep1 from "./booking-step-1";
import BookingStep2 from "./booking-step-2";
import BookingStep3 from "./booking-step-3";
import BookingStep4 from "./booking-step-4";
import Three from "../../assets/bookingeventassets/assets/3.jpg";

import "../../assets/bookingeventassets/booking.scss";

import "../../assets/bookingeventassets/calender/fonts/icomoon/style.css";
import "../../assets/bookingeventassets/calender/css/rome.css";
import "../../assets/bookingeventassets/calender/css/style.css";

import logo from "../../assets/bookingeventassets/assets/logo.svg";
import SideLogo from "../../assets/bookingeventassets/assets/sidelogo.svg";
import SideBarFootLogo from "../../assets/bookingeventassets/assets/side_bar_foot_logo.svg";
import { getDBFormatedDate, IMAGE_BASE_URL } from "../../utils/utility";
import isEmpty from "../../validation/is-empty";
import Loader from "../../components/Loader/Loader";
import ErrorPage from "../errors/ErrorPage";

class Booking extends Component {
  state = {
    isLoading: false,
    step: 1,
    event: {},
    company: {},
    selectedStaffId: null,
    selectedSlot: null,
    endSlotTime: null,
    bookingDate: null,
    name: null,
    email: null,
    messageFromCustomer: null,
    reminderPhoneNo: null,
    inviteeGuests: [],
    questionAnswers: [],
    timezondId: "",
    timezoneValue: "",
    addOns: [],
    pricing: [],
    specialRibbon: null,
    totalPrice: 0,
    error: null,
  };

  /**
   * on step two submit handler
   * @param {*} name
   * @param {*} email
   * @param {*} messageFromCustomer
   */
  onSubmitStepTwo = (
    name,
    email,
    messageFromCustomer,
    reminderPhoneNo,
    inviteeGuests,
    questionAnswers,
    addOns = [],
    pricing = [],
    isEventPaid = "E"
  ) => {
    const _this = this;
    this.setState(
      {
        name,
        email,
        messageFromCustomer,
        reminderPhoneNo,
        inviteeGuests,
        questionAnswers,
        addOns,
        pricing,
      },
      () => {
        if (isEventPaid === "D") {
          _this.onScheduleEvent({});
        }
      }
    );
  };

  /**
   * on select staff
   * @param {*} staffId
   */
  onSelectStaff = (staffId) => {
    this.setState({ selectedStaffId: staffId });
  };

  /**
   * on select timezone
   */
  onSelectTimzone = (timezondId, timezoneValue) => {
    this.setState({ timezondId, timezoneValue });
  };

  /**
   * on update step
   * @param {*} step
   */
  onUpdateStep = (step) => {
    this.setState({
      step,
    });
  };

  /**
   * on select slot
   * @param {*} slot
   */
  onSelectSlot = (slot) => {
    this.setState({
      selectedSlot: slot,
    });
  };

  /**
   * on calendar date select
   * @param {*} date
   */
  onSelectDate = (date) => {
    this.setState({
      bookingDate: getDBFormatedDate(new Date(date)),
    });
  };

  /**
   * on schedule event
   * @param {*} data
   */
  onScheduleEvent = (data) => {
    const _this = this;
    const {
      name,
      email,
      messageFromCustomer,
      reminderPhoneNo,
      questionAnswers,
      selectedStaffId,
      selectedSlot,
      event,
      bookingDate,
      company,
      addOns,
      pricing,
      timezoneValue,
      inviteeGuests,
    } = this.state;

    _this.setState({
      isLoading: true,
    });

    /*
    console.log("addons", data.addOns);
    console.log("pricing", data.pricing);
    console.log("card details", data.cardDetails);
    console.log("name", name);
    console.log("email", email);
    console.log("messageFromCustomer", messageFromCustomer);
    console.log("questionAnswers", questionAnswers);
    console.log("selectedStaffId", selectedStaffId);
    console.log("selectedSlot", selectedSlot);
    console.log("booking date", bookingDate);
    */

    // calculate booking date and time
    const bookingDateTime = bookingDate + " " + selectedSlot;
    //console.log("booking date and time", bookingDateTime);

    // calculate slot end time
    let endDate = new Date(bookingDateTime);
    if (!isNaN(event.timeslot)) {
      endDate.setTime(endDate.getTime() + event.timeslot * 60 * 1000);
    }

    const endSlot =
      ("0" + endDate.getHours()).slice(-2) +
      ":" +
      ("0" + endDate.getMinutes()).slice(-2);

    _this.setState({
      endSlotTime: endSlot,
    });

    //console.log("end Slot", endSlot);

    console.log("event", event);

    console.log(event.is_event_paid, event.is_event_paid === "E");

    if (event.is_event_paid === "E") {
      // save paid event

      const { savePaidBooking } = this.props;

      const [expMonth, expYear] = data.cardDetails.card_expiry.split("/");

      let bookingDetails = {
        card_number: data.cardDetails.card_number,
        coupon_code: data.couponCode,
        exp_month: expMonth,
        exp_year: expYear,
        cvc: data.cardDetails.cvc,
        event_id: event.id,
        booking_date_time: bookingDateTime,
        start_time: selectedSlot + ":00",
        end_time: endSlot + ":00",
        message_from_customer: messageFromCustomer,
        reminder_phone_no: reminderPhoneNo,
        business_id: company.business_id,
        timezone: timezoneValue,
        email: email,
        first_name: name,
        last_name: name,
        booking_addons: [...addOns],
        booking_pricing: [...pricing],
        questions: [...questionAnswers],
        total_price: data.totalPrice,
        invitee_guests: inviteeGuests,
      };

      _this.setState({
        totalPrice: data.totalPrice,
      });

      if (!isEmpty(selectedStaffId)) {
        bookingDetails.staff_id = selectedStaffId;
      }

      console.log("data", bookingDetails);

      savePaidBooking({
        data: {
          ...bookingDetails,
        },
        onSuccess: (data) => {
          _this.setState({
            isLoading: false,
          });
          _this.onUpdateStep(4);
        },
        onError: (error) => {
          _this.setState({
            isLoading: false,
          });
        },
      });
    } else {
      // save free event
      const { saveFreeBooking } = this.props;
      let bookingDetails = {
        event_id: event.id,
        booking_date_time: bookingDateTime,
        start_time: selectedSlot + ":00",
        end_time: endSlot + ":00",
        message_from_customer: messageFromCustomer,
        reminder_phone_no: reminderPhoneNo,
        business_id: company.business_id,
        //staff_id: 1, //seletedStaffId,
        timezone: timezoneValue,
        email: email,
        first_name: name,
        last_name: name,
        questions: [...questionAnswers],
        invitee_guests: inviteeGuests,
      };

      if (!isEmpty(selectedStaffId)) {
        bookingDetails.staff_id = selectedStaffId;
      }

      saveFreeBooking({
        data: {
          ...bookingDetails,
        },
        onSuccess: (data) => {
          _this.setState({
            isLoading: false,
          });
          _this.onUpdateStep(4);
          console.log("data", data);
        },
        onError: (error) => {
          _this.setState({
            isLoading: false,
          });
        },
      });
    }
  };

  /**
   * execute when component inserted in dom
   */
  componentDidMount() {
    const _this = this;
    const { router, getEvent } = this.props || {};
    const { params } = router || {};
    const { slug } = params || {};

    _this.setState({ isLoading: true });

    getEvent({
      data: {
        slug,
      },
      onSuccess: function (response) {
        if (isEmpty(response.company)) {
          _this.setState({
            isLoading: false,
            error: "Company information is missing!",
          });
          return;
        }

        _this.setState({
          isLoading: false,
          event: response.event,
          company: response.company,
          specialRibbon: !isEmpty(response.special_ribbon)
            ? response.special_ribbon.special_announcement
            : null,
        });
      },
      onError: (error) => {
        _this.setState({
          isLoading: false,
          error: "This event is invalid or no longer available",
        });
        notification.error(error.response.data.message);
      },
    });
  }

  render() {
    const {
      step,
      event,
      company,
      selectedSlot,
      endSlotTime,
      bookingDate,
      timezoneValue,
      addOns,
      pricing,
      specialRibbon,
      totalPrice,
      isLoading,
      inviteeGuests,
      error,
    } = this.state;
    const bookingData = {
      selectedSlot,
    };

    return (
      <>
        {error ? (
          <ErrorPage message={error} />
        ) : (
          <>
            <Loader isShowLoader={isLoading} />
            <div className="container-fluid">
              <nav className="navbar navbar-expand-sm justify-content-center top_nav shadow">
                <a className="navbar-brand" href="#">
                  <img src={logo} alt="Logo" style={{ width: "100%" }} />
                </a>
              </nav>
            </div>
            <div className="main_div booking_main_row mt-4">
              <div className="left_div">
                <nav className="navbar side_navbar">
                  <ul className="navbar-nav side_bar_ul">
                    <li className="nav-item logo">
                      <img src={SideLogo} alt="logo" />
                    </li>
                    <li className="nav-item featured">
                      {!isEmpty(event) && (
                        <img
                          className="w-100"
                          src={
                            !isEmpty(event.event_image)
                              ? IMAGE_BASE_URL + event.event_image
                              : Three
                          }
                          alt="feature_img"
                        />
                      )}
                    </li>
                    <li className="nav-item">
                      <h2>{company.company_name}</h2>
                      <p>{company.company_description}</p>
                    </li>
                    <li className="nav-item">
                      <h2>Contact Details</h2>
                      <p className="sub_head">
                        <span>Email:</span> <br />
                        {company.company_email}
                      </p>
                      <p className="sub_head">
                        <span>Phone No:</span> <br />
                        {company.company_phone_no}
                      </p>
                      <p className="sub_head">
                        <span>Address</span> <br />
                        {company.company_address}
                      </p>

                      <div className="row side_bar_foot_img_div">
                        <div className="col-12 side_bar_foot_logo_img">
                          <img src={SideBarFootLogo} />
                        </div>
                      </div>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="right_div">
                {step === 1 && !isEmpty(event) && (
                  <BookingStep1
                    event={event}
                    specialRibbon={specialRibbon}
                    timezoneValue={timezoneValue}
                    onSelectTimzone={this.onSelectTimzone}
                    onSelectStaff={this.onSelectStaff}
                    onSelectSlot={this.onSelectSlot}
                    onSelectDate={this.onSelectDate}
                    onUpdateStep={this.onUpdateStep}
                  />
                )}
                {step === 2 && (
                  <BookingStep2
                    specialRibbon={specialRibbon}
                    timezoneValue={timezoneValue}
                    onSubmitStepTwo={this.onSubmitStepTwo}
                    onScheduleEvent={this.onScheduleEvent}
                    onUpdateStep={this.onUpdateStep}
                    event={event}
                    data={bookingData}
                  />
                )}
                {step === 3 && (
                  <BookingStep3
                    specialRibbon={specialRibbon}
                    onScheduleEvent={this.onScheduleEvent}
                    onUpdateStep={this.onUpdateStep}
                    addOns={addOns}
                    pricing={pricing}
                    event={event}
                  />
                )}
                {step === 4 && (
                  <BookingStep4
                    specialRibbon={specialRibbon}
                    timezoneValue={timezoneValue}
                    totalPrice={totalPrice}
                    event={event}
                    selectedSlot={selectedSlot}
                    endSlotTime={endSlotTime}
                    bookingDate={bookingDate}
                    guests={inviteeGuests}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default connect(null, {
  getEvent,
  savePaidBooking,
  saveFreeBooking,
})(withRouter(Booking));
