import React, { useState } from "react";
import moment from "moment";
import { connect, useDispatch } from "react-redux";
import { TODAY_CALENDAR_MONTH } from "../../actions/types";
import { setCalendarView } from "../../actions/calendarActions";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import useMediaQuery from "../../hooks/useMediaQuery";
import AdminIcon from "../../assets/icons/admin-icon.svg";
import InviteeIcon from "../../assets/icons/invitee-icon.svg";

const MonthView = (props) => {
  const dispatch = useDispatch();

  const [showMorePopupDated, setShowMorePopupDated] = useState(null);
  const [showMorePopup, setShowMorePopup] = useState(false);
  const [showMoreDaySlots, setShowMoreDaySlots] = useState([]);

  /**
   * this status will only save the selected date of calendar
   */
  const [calDateSelected, setCalDateSelected] = useState(null);

  const isTablet = useMediaQuery("(min-width: 768px)");

  /**
   * toggle show more popup
   */
  const toggleShowMorePopup = () => {
    setShowMorePopup(!showMorePopup);
  };

  /**
   * render day namess
   */
  const renderDayNames = () => {
    const { selectedDate } = props.calendar;
    const dated = moment(selectedDate);
    const days = [];
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="calendar_head" key={i}>
          <span>{dated.startOf("week").add(i, "days").format("ddd")}</span>
        </div>
      );
    }
    return <div className="d-flex align-items-center">{days}</div>;
  };

  /**
   * on view more button c
   * @param {*} calendarDate
   * @param {*} daySlots
   */
  const onViewMoreHandler = (calendarDate, daySlots) => {
    console.log("dated", calendarDate, new Date(calendarDate));
    const { showMoreInPopup } = props.calendar;
    if (showMoreInPopup) {
      setShowMoreDaySlots(daySlots);
      setShowMorePopup(true);
      setShowMorePopupDated(calendarDate);
    } else {
      /*
      dispatch({
        type: TODAY_CALENDAR_MONTH,
        payload: new Date(calendarDate),
      });
      */
      props.setSelectedDate(calendarDate);
      props.setCalendarView("day");
    }
  };

  /**
   *
   * @param {*} calendarDate
   */
  const getCalendarDayEvents = (calendarDate) => {
    const { onSelectEvent, shortEventSlot } = props.calendar;
    const { events } = props;
    let eventSlots = [];
    const todayDated = moment();
    const day = calendarDate;
    const dated = moment(calendarDate).format("yyyy-MM-DD");
    // check if event is for that same date
    calendarDate = moment(calendarDate).format("yyyy-MM-DD");
    //console.log("calendar Date", calendarDate);
    var eventCount = 0;
    let daySlots = [];
    events.forEach((event, index) => {
      const { id, start, end, title } = event;
      const eventStartedDate = moment(start).format("yyyy-MM-DD");
      if (moment(calendarDate).isSame(eventStartedDate)) {
        eventCount++;

        daySlots.push({
          start,
          end,
        });
        if (isTablet === true) {
          if (!shortEventSlot) {
            if (eventSlots.length < 2) {
              // past date check
              if (day.diff(todayDated) < 0 && !todayDated.isSame(day, "day")) {
                eventSlots.push(
                  <div
                    key={index}
                    className="cal_event_detail"
                    style={{
                      backgroundColor: "rgba(31, 186, 231, 0.08)",
                      borderLeft: "3px solid rgb(31, 186, 231)",
                    }}
                  >
                    <div className="event_det">
                      <h3 style={{ color: "rgb(31, 186, 231)" }}>{title}</h3>
                      <p style={{ color: "rgb(31, 186, 231)" }}>{`${moment(
                        start
                      ).format("hh:mm a")} to ${moment(end).format(
                        "hh:mm a"
                      )}`}</p>
                    </div>
                    <div className="author_img">
                    <img src={AdminIcon} alt="" className="icon" /> 
                      <img
                        src="https://www.staging.thrive-booking.com/backend/public//uploads/profile-images/profile-1-1655986264.jpg"
                        alt="Author Profile Img" className="eve_img"
                      />
                    </div>
                  </div>
                );
              } else {
                eventSlots.push(
                  <div
                    key={index}
                    className="cal_event_detail"
                    style={{
                      backgroundColor: "rgba(31, 186, 231, 0.08)",
                      borderLeft: "3px solid rgb(31, 186, 231)",
                    }}
                    onClick={() => onSelectEvent(event)}
                  >
                    <div className="event_det">
                      <h3 style={{ color: "rgb(31, 186, 231)" }}>{title}</h3>
                      <p style={{ color: "rgb(31, 186, 231)" }}>{`${moment(
                        start
                      ).format("hh:mm a")} to ${moment(end).format(
                        "hh:mm a"
                      )}`}</p>
                    </div>
                    <div className="author_img">
                    <img src={AdminIcon} alt="" className="icon" /> 
                      <img
                        src="https://www.staging.thrive-booking.com/backend/public//uploads/profile-images/profile-1-1655986264.jpg"
                        alt="Author Profile Img" className="eve_img"
                      />
                    </div>
                  </div>
                );
              }
            }
          } else {
            // show short events
            if (eventSlots.length < 3) {
              eventSlots.push(
                <div
                  key={index}
                  className="cal_event_detail"
                  style={{
                    backgroundColor: "rgba(31, 186, 231, 0.08)",
                    borderLeft: "3px solid rgb(31, 186, 231)",
                  }}
                  onClick={() => onSelectEvent(event)}
                >
                  <div className="event_det">
                    <p style={{ color: "rgb(31, 186, 231)" }}>{`${moment(
                      start
                    ).format("hh:mm a")} - ${moment(end).format(
                      "hh:mm a"
                    )}`}</p>
                  </div>
                </div>
              );
            }
          }
        }
      }
    });

    if (!shortEventSlot ? eventCount > 2 : eventCount > 3) {
      eventSlots.push(
        <>
          <button
            className="btn btn_more p-0"
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              onViewMoreHandler(dated, daySlots);
            }}
            style={{ marginTop: "-5px" }}
          >{`+${eventCount - (!shortEventSlot ? 2 : 3)} `}<span>more</span></button>
        </>
      );
    }

    return eventSlots;
  };

  /**
   * render mobile event slots below calendar
   */
  const renderMobileSlots = () => {
    const { onSelectEvent, shortEventSlot } = props.calendar;
    const { events } = props;
    let eventSlots = [];

    /*
    if (calDateSelected) {
      let calendarDate = moment(calDateSelected, "DD-MM-YYYY");
      const todayDated = moment();
      const day = calendarDate;
      const dated = moment(calendarDate).format("yyyy-MM-DD");
      // check if event is for that same date
      calendarDate = moment(calendarDate).format("yyyy-MM-DD");
      var eventCount = 0;
      let daySlots = [];
      
      
     
    }
    */

    if (calDateSelected) {
      let calendarDate = moment(calDateSelected, "DD-MM-YYYY");
      const todayDated = moment();
      const day = calendarDate;
      const dated = moment(calendarDate).format("yyyy-MM-DD");
      // check if event is for that same date
      calendarDate = moment(calendarDate).format("yyyy-MM-DD");
      //console.log("calendar Date", calendarDate);
      var eventCount = 0;
      let daySlots = [];
      events.forEach((event, index) => {
        const { id, start, end, title } = event;
        const eventStartedDate = moment(start).format("yyyy-MM-DD");
        if (moment(calendarDate).isSame(eventStartedDate)) {
          eventCount++;

          daySlots.push({
            start,
            end,
          });

          if (!shortEventSlot) {
            // past date check
            if (day.diff(todayDated) < 0 && !todayDated.isSame(day, "day")) {
              eventSlots.push(
                <div
                  key={index}
                  className="cal_event_detail"
                  style={{
                    backgroundColor: "rgba(31, 186, 231, 0.08)",
                    borderLeft: "3px solid rgb(31, 186, 231)",
                  }}
                >
                  <div className="event_det">
                    <h3 style={{ color: "rgb(31, 186, 231)" }}>{title}</h3>
                    <p style={{ color: "rgb(31, 186, 231)" }}>{`${moment(
                      start
                    ).format("hh:mm a")} to ${moment(end).format(
                      "hh:mm a"
                    )}`}</p>
                  </div>
                  <div className="author_img">
                  <img src={AdminIcon} alt="" className="icon" /> 
                      <img
                        src="https://www.staging.thrive-booking.com/backend/public//uploads/profile-images/profile-1-1655986264.jpg"
                        alt="Author Profile Img" className="eve_img"
                      />
                  </div>
                </div>
              );
            } else {
              eventSlots.push(
                <div
                  key={index}
                  className="cal_event_detail"
                  style={{
                    backgroundColor: "rgba(31, 186, 231, 0.08)",
                    borderLeft: "3px solid rgb(31, 186, 231)",
                  }}
                  onClick={() => onSelectEvent(event)}
                >
                  <div className="event_det">
                    <h3 style={{ color: "rgb(31, 186, 231)" }}>{title}</h3>
                    <p style={{ color: "rgb(31, 186, 231)" }}>{`${moment(
                      start
                    ).format("hh:mm a")} to ${moment(end).format(
                      "hh:mm a"
                    )}`}</p>
                  </div>
                  <div className="author_img">
                  <img src={AdminIcon} alt="" className="icon" /> 
                      <img
                        src="https://www.staging.thrive-booking.com/backend/public//uploads/profile-images/profile-1-1655986264.jpg"
                        alt="Author Profile Img" className="eve_img"
                      />
                  </div>
                </div>
              );
            }
          } else {
            // show short events
            eventSlots.push(
              <div
                key={index}
                className="cal_event_detail"
                style={{
                  backgroundColor: "rgba(31, 186, 231, 0.08)",
                  borderLeft: "3px solid rgb(31, 186, 231)",
                }}
                onClick={() => onSelectEvent(event)}
              >
                <div className="event_det">
                  <p style={{ color: "rgb(31, 186, 231)" }}>{`${moment(
                    start
                  ).format("hh:mm a")} - ${moment(end).format("hh:mm a")}`}</p>
                </div>
              </div>
            );
          }
        }
      });
    }

    return eventSlots;
  };

  /**
   * render cells, in future when we have events we will get it from events params
   */
  const renderCells = () => {
    const { onSelectSlot } = props.calendar;
    const { selectedDate, currentDate } = props;
    const monthStart = moment(selectedDate).startOf("month");
    const monthEnd = moment(selectedDate).endOf("month");
    const startDate = moment(monthStart).startOf("week");
    const endDate = moment(monthEnd).endOf("week");
    const dateFormat = "D";
    let days = [];
    let day = startDate;
    let formattedDate = "";

    const todayDated = moment();

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        const cloneDay = day;
        formattedDate = moment(day).format(dateFormat);
        const cloneDayFormatted = moment(day).format("DD-MM-YYYY");
        const calendarDayEvents = getCalendarDayEvents(day);

        let daySlotProps = {
          onSelectSlotHandler: (cloneDayFormatted, event) => {
            onSelectSlot(cloneDayFormatted, event);
          },
        };

        // if not same month then disable edit
        if (!moment(monthStart).isSame(day, "month")) {
          daySlotProps.onSelectSlotHandler = (cloneDayFormatted, event) => {};
        }

        // check if day is not past date
        if (day.diff(todayDated) < 0 && !todayDated.isSame(day, "day")) {
          // push disable day, past date
          days.push(
            <div className={`calendar_day disable`} key={day}>
              <div
                className={`day ${
                  moment(currentDate).isSame(day, "day") ? "today" : ""
                }`}
              >
                {formattedDate}
              </div>
              {calendarDayEvents.length > 0 && calendarDayEvents}
            </div>
          );
        } else {
          // not past date
          days.push(
            <div
              className={`calendar_day ${
                !moment(monthStart).isSame(day, "month") ? "disable" : ""
              } ${
                moment(calDateSelected, "DD-MM-YYYY").isSame(day, "day")
                  ? "is_selected"
                  : ""
              }
              `}
              key={day}
              onClick={(event) => {
                setCalDateSelected(cloneDayFormatted);
                daySlotProps.onSelectSlotHandler(cloneDayFormatted, event);
              }}
            >
              <div
                className={`day ${
                  moment(currentDate).isSame(day, "day") ? "today" : ""
                }`}
              >
                {formattedDate}
              </div>
              {calendarDayEvents.length > 0 && calendarDayEvents}
            </div>
          );
        }

        day = day.add(1, "days");
      }
    }

    return days;
  };

  return (
    <>
      {showMorePopup && (
        <Modal
          centered={true}
          isOpen={showMorePopup}
          toggle={toggleShowMorePopup}
          className="more_eve_popup"
        >
          <ModalHeader toggle={toggleShowMorePopup}>
            {moment(showMorePopupDated, "yyyy-MM-DD").format("DD MMMM, yyyy")}
          </ModalHeader>
          <ModalBody>
            {showMoreDaySlots.map((slot, index) => (
              <button
                className="eve_slot"
                key={index}
                style={{
                  backgroundColor: "rgba(31, 186, 231, 0.08)",
                  borderColor: "rgb(31, 186, 231)",
                  color: "rgb(31, 186, 231)",
                }}
              >{`${moment(slot.start).format("hh:mm a")} to ${moment(
                slot.end
              ).format("hh:mm a")}`}</button>
            ))}
          </ModalBody>
        </Modal>
      )}
      <div className="calendar_wrapper">
        {/* Calendar Header */}
        <div className="calendar_header">{renderDayNames()}</div>
        <div className="calendar_body">{renderCells()}</div>
      </div>
      {!isTablet && renderMobileSlots()}
    </>
  );
};

const mapStateToProps = (state) => ({
  calendar: state.calendar,
});

export default connect(mapStateToProps, {
  setCalendarView,
})(MonthView);
